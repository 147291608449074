import { makeFormURLRequest, makeRequest } from "../request"
import qs from "qs"

interface ILogin {
  email: string
  password: string
}

interface IRegister {
  email: string
  password: string
  confirm_password: string
}

class CategoryController {
  constructor() { }

  createCategory = (name) => {
    return makeRequest(
      "post",
      `/api/categories/${localStorage.getItem("current_store_id")}`,
      {
        name,
      },
      {
        authorization: `Bearer ${sessionStorage.getItem("hacker_you")}`,
      }
    )
  }

  getCategories = () => {
    return makeRequest(
      "get",
      `/api/categories/${localStorage.getItem("current_store_id")}`,
      {},
      {
        authorization: `Bearer ${sessionStorage.getItem("hacker_you")}`,
      }
    )
  }
}

export default new CategoryController()
