import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Layout from "../../../components/templates/layout"
import New from "../../../components/views/retail/pricing/new"

const NewPage = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <New />
      </Layout>
    </DndProvider>
  )
}

export default NewPage
